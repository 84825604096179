import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/layout";
import ManageContent from "./routes/content/manage-content";
import AddMoviePage from "./routes/content/add-movie";
import UploadVideo from "./routes/content/upload-video";
import {
  AuthProvider,
  GetRefreshToken,
  // GetMe,
  RequireRoot,
  RequireLogin,
} from "./lib/context/auth.context";
import Login from "./routes/auth/login";
import UpdateRoot from "./routes/auth/update-root";
import LoginRoot from "./routes/auth/login-root";
import { ConfirmRootRequest } from "./routes/auth/confirm-root-request";
import ConfirmRootToken from "./routes/auth/confirm-root-token";
import Auth from "./routes/auth/auth";
import ConfirmEmailSuccess from "./routes/auth/confirm-email-success";
import ManageStaff from "./routes/users/manage-users";
import RegisterAdmin from "./routes/auth/register-admin";
import { ContentProvider } from "./lib/context/content.context";
import MovieListing from "./routes/content/movie-listing";
import ManageGenres from "./routes/content/manage-genres";
import AddSeriesPage from "./routes/content/add-series";
import AddSeasonPage from "./routes/content/add-season";
import ViewSeriesPage from "./routes/content/view-series";
import AddEpisodePage from "./routes/content/add-episode";
import ViewEpisodesPage from "./routes/content/view-episodes";
import ManageCastPage from "./routes/content/manage-cast";
import EditMoviePage from "./routes/content/editMovie";
import ManageRatings from "./routes/content/manage-rating";
import ManageAdverts from "./routes/content/manage-adverts";
import EditSeasonPage from "./routes/content/edit-season";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <GetRefreshToken>
          {/* <RequireRoot> */}
          {/* <GetMe> */}
          <Routes>
            //FIXME we have a bug here that loads auth routes even when isLogin
            is true
            <Route path="/auth" element={<Auth />}>
              <Route path="update-root" element={<UpdateRoot />} />
              <Route path="login-root" element={<LoginRoot />} />
              <Route
                path="login"
                element={
                  <RequireLogin>
                    <Login />
                  </RequireLogin>
                }
              />
              <Route
                path="confirm-root-request"
                element={<ConfirmRootRequest />}
              />
              <Route
                path="confirm-root-token/:token"
                element={<ConfirmRootToken />}
              />
              <Route
                path="confirm-email-success"
                element={<ConfirmEmailSuccess />}
              />
              <Route path="register/:token" element={<RegisterAdmin />} />
            </Route>
            <Route
              path="/"
              element={
                <RequireLogin>
                  <ContentProvider>
                    <Layout />
                  </ContentProvider>
                </RequireLogin>
              }
            >
              <Route path="manage-content" element={<ManageContent />}>
                <Route path="add-movie" element={<AddMoviePage />} />
                <Route path="edit-movie/:movieId" element={<EditMoviePage />} />
                <Route path="add-series" element={<AddSeriesPage />} />
                <Route
                  path="add-season/:id/:title"
                  element={<AddSeasonPage />}
                />
                <Route
                  path="series/:seriesId/season/:seasonId/edit-season/:seriesTitle"
                  element={<EditSeasonPage />}
                />
                <Route
                  path="view-series/:id/:title"
                  element={<ViewSeriesPage />}
                />
                <Route
                  path="series/:seriesId/:seriesTitle/season/add-episode/:seasonId"
                  element={<AddEpisodePage />}
                />
                <Route
                  path="series/:seriesId/season/:seasonId/view-episodes"
                  element={<ViewEpisodesPage />}
                />
                <Route path="movie-listing" element={<MovieListing />}></Route>
                <Route path="upload-video/:id" element={<UploadVideo />} />
                <Route path="manage-genres" element={<ManageGenres />} />
                <Route path="manage-cast" element={<ManageCastPage />} />
                <Route path="manage-ratings" element={<ManageRatings />} />
                <Route path="manage-adverts" element={<ManageAdverts />} />
              </Route>
              <Route path="manage-staff" element={<ManageStaff />} />
            </Route>
          </Routes>
          {/* </GetMe> */}
          {/* </RequireRoot> */}
        </GetRefreshToken>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
