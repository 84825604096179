import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import { useContentApi } from "../../lib/context/content.context";
import { IGenre } from "../../lib/types/movie.types";
import EditIcon from "@mui/icons-material/Edit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Input } from "antd";
import * as yup from "yup";

const genreSchema = yup.object().shape({
  name: yup.string().required().max(255).min(3),
});

export default function ManageGenres() {
  const [genres, setGenres] = useState<IGenre[]>([]);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState<boolean>(false);
  const [getGenresError, setGetGenresError] = useState(false);
  const [genresLoading, setGenresLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const contentApi = useContentApi();

  useEffect(() => {
    contentApi.getGenres((success, payload) => {
      setGenresLoading(true);
      if (success) {
        setGetGenresError(false);
        setGenresLoading(false);
        setReload(false);
        return setGenres(payload);
      }

      return setGetGenresError(true);
    });
  }, [reload]);

  return (
    <section className="h-full grid grid-cols-12">
      <div className="col-span-8 ">
        <GenreList
          genres={genres}
          getGenresError={getGenresError}
          genresLoading={genresLoading}
        />
      </div>
      <div className="col-span-4 flex flex-col gap-12">
        <h2 className="text-2xl">Add a genre</h2>
        <Formik
          initialValues={{
            name: "",
            error: "",
          }}
          onSubmit={({ name }, { setSubmitting, setFieldError, resetForm }) => {
            contentApi.addGenre({ name }, (success) => {
              setSubmitting(false);

              if (success) {
                resetForm();
                setReload(true);
                return;
              }

              return setFieldError("error", "Error making request");
            });
          }}
          validationSchema={genreSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ErrorMessage
                name="error"
                component="div"
                className="text-red-500"
              />
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Name
                <Field type="text" name="name" as={Input} />
                <ErrorMessage name="name" component="div" />
              </label>
              <button
                type="submit"
                className="bg-cyan-600 px-5 py-1 text-white text-sm hover:bg-cyan-700 "
              >
                {isSubmitting ? <p>Loading</p> : <p>Submit</p>}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

const GenreList = ({ genres, getGenresError, genresLoading }: any) => {
  if (getGenresError) {
    return (
      <section className="flex p-5">
        <div>Error getting genres</div>
      </section>
    );
  }

  if (genres.length === 0) {
    return (
      <section className="flex flex-col p-5">
        {genresLoading && <Loader />}
        <div>No Genres found. Kindly add a genre.</div>
      </section>
    );
  }

  return (
    <section className="flex flex-col p-5">
      {genresLoading && <Loader />}
      <div className="flex flex-wrap gap-5">
        {genres.map((genre: IGenre, index: number) => {
          return <Genre key={index} genre={genre} />;
        })}
      </div>
    </section>
  );
};

const Genre = ({ genre }: any) => {
  const handleClick = () => {};
  console.log(genre.name);

  return (
    <Chip
      label={genre.name}
      variant="outlined"
      onClick={handleClick}
      icon={<EditIcon sx={{ fontSize: 10 }} />}
      size="medium"
    />
  );
};
