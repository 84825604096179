import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SERVER_URL } from "../../config/server";
import { getAccessToken } from "../../lib/authTokens/accessToken";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

const UploadVideo: React.FunctionComponent = () => {
  const [video, setVideo] = useState<null | File>(null);
  const [isLoading, setIsLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let title = searchParams.get("title");
  const params = useParams();
  const navigate = useNavigate();

  const uploadToS3 = async (filename: string, file: any): Promise<any> => {
    setIsLoading(true);
    const config = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID!, 
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESSKEY!
    }

    const loader = document.getElementById("loader");
    const bar = document.getElementById("progress-bar");
    const uploadVideo = document.getElementById("uploadVideo");

    loader!.classList.replace("hidden", "block");
    uploadVideo?.classList.replace("block", "hidden");

    //TODO Calculate the number of parts a video could have 
    // const numberOfParts = Math.ceil(file.size / 100);

    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({region: "eu-west-2", credentials: config}),
        params: { Bucket: "media-fronti", Key: filename, Body: file, ContentType: file.type, ContentDisposition: 'inline'},
        //TODO should add logic to resume an upload from the last part upload
        // queueSize: numberOfParts, // optional concurrency configuration
        partSize: 1024 * 1024 * 100, // optional size of each part, in bytes, at least 5MB
        leavePartsOnError: false, // optional manually handle dropped parts
      });
    
      parallelUploads3.on("httpUploadProgress", (progress: any) => {
        const uploadProgress = Math.round((progress.loaded / progress.total) * 100);
        bar!.setAttribute("value", uploadProgress.toString())
        bar!.previousElementSibling!.textContent = `${uploadProgress}%`

        if( uploadProgress === 100 ){
          setIsLoading(false);
          bar!.previousElementSibling!.textContent = `Completed!` 
          navigate("/manage-content/movie-listing")
        }
      });
    
      const res: any = await parallelUploads3.done();
      const url = res.Location as string; 
      return url;
    } catch (error) {
      console.log(error);
      return error
    }
  }

  // Add validation
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const url = await uploadToS3(video!.name, video)

    if(url){
      //Send url to the backend
      const config = {
        headers: {
          'Authorization': `bearer ${getAccessToken()}`,
        }
      }

      const response = await axios.put(`${SERVER_URL}/movies/${params.id}`, {movieUrl: url}, config);
    }
  };

  return (
    <section className="h-full">
      <h2 className="font-medium text-2xl">Add video for: {title}</h2>
      <hr className="my-2" />
      <UploadVideoForm handleSubmit={handleSubmit} setVideo={setVideo} />
      <Loader />
    </section>
  );
};

export default UploadVideo;

const Loader = () => {
  return (
    <div id="loader" className="hidden">
      <label htmlFor="progress-bar">0%</label>
      <progress id="progress-bar" value={0} max={100}/>
    </div>
  )
}

const UploadVideoForm = ({ handleSubmit, setVideo }: any) => {
  return (
    <form
      encType="multipart/form-data"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      className="mt-5 block"
      id="uploadVideo"
    >
      <div className="flex w-full">
        <label className="w-1/6" htmlFor="">
          Upload Video
        </label>
        <input
          className="w-5/6"
          type="file"
          name="video"
          onChange={(e) => setVideo(e.target.files![0])}
        />
      </div>

      <button
        type="submit"
        className="bg-cyan-500 hover:bg-cyan-700 text-white mt-10 py-1 px-10"
      >
        save
      </button>
    </form>
  );
};
