let accessToken = "";

export const setAccessToken = (s: string) => {
	accessToken = s;
	localStorage.setItem('act', s);
	// console.log(s)
};

export const getAccessToken = () => {
	return localStorage.getItem('act')
};


export const setRefreshToken = (s: string) => {
	localStorage.setItem('rft', s);

};

export const getRefreshToken = () => {
	return localStorage.getItem('rft')
};


export const setIsLoggedIn = (s: string) => {
	localStorage.setItem("lgn", s)
}

export const getIsLoggedIn = () => {
	return localStorage.getItem('lgn');
}