import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import { useContentApi } from "../../lib/context/content.context";
import { IGenre } from "../../lib/types/movie.types";
import EditIcon from "@mui/icons-material/Edit";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Input } from "antd";
import * as yup from "yup";

const ratingSchema = yup.object().shape({
  name: yup.string().required().max(10).min(1),
});

export default function ManageRatings() {
  const [ratings, setRatings] = useState<any>([]);
  const [getRatingError, setGetRatingsError] = useState(false);
  const [genresLoading, setRatingsLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const contentApi = useContentApi();

  useEffect(() => {
    contentApi.getRatings((success, payload) => {
      setRatingsLoading(true);
      if (success) {
        setGetRatingsError(false);
        setRatingsLoading(false);
        setReload(false);
        return setRatings(payload);
      }

      return setGetRatingsError(true);
    });
  }, [reload]);

  return (
    <section className="h-full grid grid-cols-12">
      <div className="col-span-8 ">
        <RatingList
          ratings={ratings}
          getRatingsError={getRatingError}
          ratingsLoading={genresLoading}
        />
      </div>
      <div className="col-span-4 flex flex-col gap-12">
        <h2 className="text-2xl">Add a rating</h2>
        <Formik
          initialValues={{
            name: "",
            error: "",
          }}
          onSubmit={({ name }, { setSubmitting, setFieldError, resetForm }) => {
            contentApi.addRating({ name }, (success) => {
              setSubmitting(false);
              resetForm();

              console.log(success);

              if (success) {
                setReload(true);
                return;
              }

              return setFieldError("error", "Error making request");
            });
          }}
          validationSchema={ratingSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ErrorMessage
                name="error"
                component="div"
                className="text-red-500"
              />
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Name
                <Field type="text" name="name" as={Input} />
                <ErrorMessage name="name" component="div" />
              </label>
              <button
                type="submit"
                className="bg-cyan-600 px-5 py-1 text-white text-sm hover:bg-cyan-700 "
              >
                {isSubmitting ? <p>Loading</p> : <p>Submit</p>}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
}

const RatingList = ({ ratings, getRatingsError, ratingsLoading }: any) => {
  // console.log(ratings);
  if (getRatingsError) {
    return (
      <section className="flex p-5">
        <div>Error getting ratings</div>
      </section>
    );
  }

  if (ratings.length === 0) {
    return (
      <section className="flex flex-col p-5">
        {ratingsLoading && <Loader />}
        <div>No Ratings found. Kindly add a rating.</div>
      </section>
    );
  }

  return (
    <section className="flex flex-col p-5">
      {ratingsLoading && <Loader />}
      <div className="flex flex-wrap gap-5">
        {ratings.map((rating: IGenre, index: number) => {
          return <Rating key={index} rating={rating} />;
        })}
      </div>
    </section>
  );
};

const Rating = ({ rating }: any) => {
  const handleClick = () => {};
  // console.log(rating.name);

  return (
    <Chip
      label={rating.name}
      variant="outlined"
      onClick={handleClick}
      icon={<EditIcon sx={{ fontSize: 10 }} />}
      size="medium"
    />
  );
};
