import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAccessToken } from "../../lib/authTokens/accessToken";
const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

export default function ViewSeriesPage() {
  const [state, setState] = useState<any>({
    error: false,
    loading: false,
    seasons: [],
  });
  const params = useParams();

  const handleError = (val: boolean) => {
    let oldState = { ...state };
    oldState.error = val;
    setState(oldState);
  };

  const handleLoading = (val: boolean) => {
    let oldState = { ...state };
    oldState.error = val;
    setState(oldState);
  };

  useEffect(() => {
    const getSeasons = async () => {
      handleLoading(true);
      const response = await fetch(
        `${SERVER_URL}/movies/series/${params.id}/seasons`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${getAccessToken()}`,
          },
        }
      );

      if (response.status === 200) {
        handleLoading(false);
        handleError(false);
        const result = await response.json();
        const oldState = { ...state };
        oldState.seasons = result;
        return setState(oldState);
      }

      handleLoading(false);
      return handleError(true);
    };
    getSeasons();
  }, []);

  return (
    <section className="flex flex-col gap-10">
      <h2>{params.title}</h2>
      <Link to={`/manage-content/add-season/${params.id}/${params.title}`}>
        Add Season
      </Link>
      {state.seasons.map((season: any) => (
        <div className="flex gap-10 items-center">
          <Link
            to={`/manage-content/series/${params.id}/season/${season.seasonId}/view-episodes`}
          >
            <p>Season {season.title}</p>
          </Link>
          <Link
            to={`/manage-content/series/${params.id}/${params.title}/season/add-episode/${season.seasonId}/`}
          >
            <p>Add Episode</p>
          </Link>
          <Link
            to={`/manage-content/series/${params.id}/season/${season.seasonId}/edit-season/${params.title}`}
          >
            <p>Edit </p>
          </Link>
        </div>
      ))}
    </section>
  );
}
