import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../lib/context/auth.context";

export default function ConfirmRootToken() {
  const params = useParams();
  const auth = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    auth.rootConfirm(params.token!, (success) => {
      console.log("email confirmed ", success);
      if (!success) {
        /**
         * Maybe link expired or token is invalid
         * we ask them to regenerate a token
         */
        return navigate("/auth/confirm-root-request", {
          replace: true,
        });
      }

      // /** We force a re-fetch of rootMeta credentials to avoid a UX Bug.
      //  *  What happens is when we successfully
      //  *
      //  */
      // auth.getRootMeta();
      return navigate("/auth/confirm-email-success", {
        replace: true,
      });
    });
  }, []);

  return <></>;
}
