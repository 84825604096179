import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { getAccessToken } from "../../lib/authTokens/accessToken";
import * as yup from "yup";
import axios from "axios";
import { DatePicker, Input, Select } from "antd";
import { useDataApi } from "../../hooks/useDataApi";

const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

const CustomSelect: any = Select;
const { Option } = Select;

const seasonSchema = yup.object().shape({
  title: yup.number().min(1),
  trailer: yup.mixed(),
  releaseDate: yup.string(),
  closeDate: yup.string(),
  adverts: yup.array(),
});

const EditSeasonPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [{ data: season, isError: seasonError, isLoading: seasonLoading }] =
    useDataApi(
      `${SERVER_URL}/movies/series/${params.seriesId}/seasons/${params.seasonId}`
    );

  const [{ data: adverts, isError: advertsError, isLoading: advertsLoading }] =
    useDataApi(`${SERVER_URL}/advert`);

  //   console.log(season.title);

  return (
    <>
      <h1 className="text-xl">Edit Season</h1>
      {seasonLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            title: season ? season.title : "",
            error: "",
            trailer: season ? season.trailerUrl : "",
            releaseDate: season ? season.releaseDate : "",
            closeDate: season ? season.releaseDate : "",
            adverts: "",
          }}
          onSubmit={async (
            values,
            { resetForm, setSubmitting, setFieldError }
          ) => {
            const formData = new FormData();
            formData.append("title", ` ${values.title}`);
            formData.append("trailer", values.trailer!);
            formData.append("releaseDate", values.releaseDate);
            formData.append("closeDate", values.closeDate);
            formData.append("adverts", values.adverts);

            console.log(values);

            const response = await axios.put(
              `${SERVER_URL}/movies/series/${params.seriesId}/seasons/${params.seasonId}`,
              formData,
              {
                headers: {
                  authorization: `bearer ${getAccessToken()}`,
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.status === 200) {
              resetForm();
              setSubmitting(false);
              return navigate(
                `/manage-content/view-series/${params.seriesId}/${params.seriesTitle}`
              );
            }

            setFieldError("error", "Server Error");
          }}
          validationSchema={seasonSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="flex flex-col w-2/5">
              <ErrorMessage
                className="text-red-500"
                name="error"
                component="div"
              />
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Title
                <Field
                  type="text"
                  name="title"
                  as={Input}
                  //   defaultValue={season ? season.title : ""}
                />
                <ErrorMessage name="title" component="div" />
              </label>

              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Trailer
                <input
                  type="file"
                  name="trailer"
                  onChange={(e: any) =>
                    setFieldValue("trailer", e.target.files[0])
                  }
                />
                <ErrorMessage name="trailer" component="div" />
              </label>

              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Ads
                <CustomSelect
                  name="adverts"
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select genre"
                  onChange={(value: any) => setFieldValue("adverts", value)}
                  defaultValue={
                    season
                      ? season.adverts.map((advert: any) => advert.title)
                      : []
                  }
                >
                  {adverts
                    ? adverts.data.map((ad: any, index: any) => (
                        <Option key={ad.advertId}>{ad.title}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="adverts" component="div" />
              </label>

              <label className="flex flex-col w-full gap-y-[1rem] my-[1rem]">
                ReleaseDate
                <DatePicker
                  name="releaseDate"
                  onChange={(date, dateString) =>
                    setFieldValue("releaseDate", dateString)
                  }
                />
                <ErrorMessage name="releaseDate" component="div" />
              </label>
              <label className="flex flex-col w-full gap-y-[1rem] my-[1rem]">
                CloseDate
                <DatePicker
                  name="closeDate"
                  onChange={(date, dateString) =>
                    setFieldValue("closeDate", dateString)
                  }
                />
                <ErrorMessage name="closeDate" component="div" />
              </label>

              <button type="submit">
                {isSubmitting ? <p>Loading</p> : <p>Submit</p>}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default EditSeasonPage;
