import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Select } from "antd";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { useContentApi } from "../../lib/context/content.context";
import { useDataApi } from "../../hooks/useDataApi";
import { getAccessToken } from "../../lib/authTokens/accessToken";
const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

const advertSchema = yup.object().shape({
  title: yup.string().required().max(10).min(3),
  rating: yup.string().max(10).min(1).required(),
  advert: yup.mixed().required(),
});

const CustomSelect: any = Select;
const { Option } = Select;

const AdvertList = ({ adverts }: any) => {
  const columns = [
    { field: "title", headerName: "Title", width: 200 },
    { field: "rating", headerName: "Rating", width: 200 },
    { field: "videoUrl", headerName: "Link", width: 400 },
  ];

  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={adverts ? adverts : []}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getRowId={(row) => row.advertId}
          checkboxSelection
        />
      </div>
    </div>
  );
};

const ManageAdvertsPage = () => {
  const content = useContentApi();
  const [adverts, setAdverts] = useState<any>(null);
  const [isError, setIsError] = useState(false);
  const [isReloading, setIsReloading] = useState(true);

  const [{ data: ratings, isError: ratingsError, isLoading: ratingsLoading }] =
    useDataApi(`${SERVER_URL}/rating`);

  useEffect(() => {
    console.log("reloading");
    const fetchAdverts = async () => {
      const result = await axios.get(`${SERVER_URL}/advert`);
      setAdverts(result.data.data);
      // console.log(result.data.data);
    };

    fetchAdverts();
  }, [isReloading]);

  return (
    <section>
      <p className="text-2xl mb-[2rem]">Manage adverts page</p>
      <div className="grid grid-cols-12 gap-10">
        <div className="col-span-4">
          <p>{isError ? "Error submitting" : null}</p>
          <Formik
            initialValues={{
              title: "",
              rating: "",
              advert: "",
              error: "",
            }}
            validationSchema={advertSchema}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setFieldError }
            ) => {
              const formData = new FormData();
              formData.append("title", values.title);
              formData.append("rating", values.rating);
              formData.append("advert", values.advert);
              // console.log(values);

              try {
                const res = await axios.post(`${SERVER_URL}/advert`, formData, {
                  headers: {
                    authorization: `bearer ${getAccessToken()}`,
                  },
                });

                if (res.status === 200) {
                  console.log("success");
                  setIsReloading(true);
                  resetForm();
                  setSubmitting(false);
                  return;
                }
              } catch (error) {
                setSubmitting(false);
                return setFieldError("error", "error submmiting form");
              }
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col gap-8">
                <ErrorMessage
                  name="error"
                  className="text-red-500"
                  component="div"
                />
                <Field
                  className="border-2 px-3 py-2"
                  type="text"
                  name="title"
                  placeholder="title"
                />
                <ErrorMessage name="title" component="div" />
                <label
                  htmlFor=""
                  className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
                >
                  Rating / Parental guidance
                  <CustomSelect
                    name="rating"
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    placeholder="Please select a rating"
                    onChange={(value: any) => setFieldValue("rating", value)}
                  >
                    {ratings
                      ? ratings.data.map((rating: any, index: any) => (
                          <Option key={rating.ratingId}>{rating.name}</Option>
                        ))
                      : null}
                  </CustomSelect>
                  <ErrorMessage name="rating" component="div" />
                </label>
                <label
                  htmlFor=""
                  className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
                >
                  Advert File
                  <input
                    type="file"
                    name="advert"
                    onChange={(e: any) =>
                      setFieldValue("advert", e.target.files[0])
                    }
                  />
                  <ErrorMessage name="advert" component="div" />
                </label>
                <button
                  className="bg-cyan-500 py-2 px-3 text-white"
                  type="submit"
                >
                  {isSubmitting ? <p>Loading</p> : <p>Submit</p>}
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col-span-8">
          <AdvertList adverts={adverts} />
        </div>
      </div>
    </section>
  );
};

export default ManageAdvertsPage;
