import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

const RegisterAdmin = () => {
  const [email, setEmail] = useState<string>(null!);
  const [password, setPassword] = useState<string>(null!);
  const [dateOfBirth, setDateOfBirth] = useState<string>(null!);
  const [userName, setUsername] = useState<string>(null!);
  const [isError, setIsError] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let response = await fetch(
      `${SERVER_URL}/admin/auth/register-admin/${params.token}`,
      {
        method: "POST",
        body: JSON.stringify({ email, password, dateOfBirth, userName }),
        // credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 201) {
      setIsError(false);
      return navigate("/", { replace: true });
    }

    return setIsError(true);
  };

  return (
    <section className="h-screen flex flex-col gap-3 justify-center items-center">
      <h1 className="text-3xl">REGISTER</h1>
      {isError && (
        <p className="bg-red-600">
          An error occured, kindly contact your admin
        </p>
      )}
      <form
        className="p-5 bg-slate-100 flex flex-col"
        onSubmit={(e) => handleSubmit(e)}
      >
        <input
          type="text"
          className="border-2 px-4 py-2 mb-2"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="text"
          className="border-2 px-4 py-2 mb-2"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          className="border-2 px-4 py-2 mb-2"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="flex gap-3 items-center">
          <label htmlFor="">Date of Birth</label>
          <input
            type="date"
            className="border-2 px-4 py-2 mb-2"
            placeholder=""
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
        </div>
        <button className="px-4 py-2 bg-red-500 mt-5 text-white">
          REGISTER
        </button>
      </form>
    </section>
  );
};

export default RegisterAdmin;
