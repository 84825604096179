import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { useEffect, useState } from "react";
import { getAccessToken } from "../../lib/authTokens/accessToken";
const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

const UserList = () => {
  const [users, setUsers] = useState<any>(null);
  const columns = [
    { field: "userId", headerName: "ID", width: 70 },
    { field: "userName", headerName: "User name", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "confirmed", headerName: "Confirmed Email", width: 200 },
    { field: "joined", headerName: "Joined", width: 130 },
    { field: "isActive", headerName: "Active", width: 130 },
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      const result = await fetch(`${SERVER_URL}/users/`);
      setUsers(await result.json());
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={users ? users : []}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getRowId={(row) => row.userId}
          checkboxSelection
        />
      </div>
    </div>
  );
};

const ManageStaff = () => {
  const [roles, setRoles] = useState<any>(null);
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState<any>(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchRoles = async () => {
      const result = await fetch(`${SERVER_URL}/roles`);
      setRoles(await result.json());
      setReload(false);
    };

    fetchRoles();
  }, [reload]);

  const handleRoles = (values: any) => {
    const previousValues = selectedRoles;
    const result = values.map((role: any, index: any) => {
      if (role !== previousValues[index]) {
        return role.name;
      }
      return previousValues[index];
    });

    setSelectedRoles(result);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const response = await fetch(`${SERVER_URL}/admin/users/invite`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify({ email, roles: selectedRoles }),
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${getAccessToken()}`,
      },
    });

    if (response.status === 200) {
      setReload(true);
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  };
  return (
    <div className="p-5">
      <p className="text-3xl mb-5">Invite Users</p>
      <section className=" flex items-center">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="flex gap-7 items-center"
        >
          <div className="flex gap-5 items-center">
            <p className="font-medium">Select roles to apply</p>
            <Autocomplete
              multiple
              limitTags={2}
              size="small"
              id="multiple-limit-tags"
              options={roles ? roles : []}
              getOptionLabel={(role: any) => role.name}
              renderInput={(params) => (
                <TextField {...params} label="Roles" placeholder="Roles" />
              )}
              sx={{ width: "500px" }}
              onChange={(e, value) => handleRoles(value)}
            />
            <div className="flex gap-5 items-center">
              <label htmlFor="">Email</label>
              <input
                className="border-2 px-4 py-2"
                type="email"
                placeholder="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <button className="bg-blue-500 px-4 py-2 text-white">Invite</button>
        </form>
      </section>
      <hr className="my-5" />
      <section className="mt-[2rem]">
        <UserList />
      </section>
    </div>
  );
};

export default ManageStaff;
