import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../lib/context/auth.context";

const LoginRoot = () => {
  const [email, setEmail] = useState<string>(null!);
  const [password, setPassword] = useState<string>(null!);
  const [error, setError] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    auth.login({ email, password }, (success) => {
      if (!success) {
        return setError(true);
      }

      setError(false);
      return navigate("/", { replace: true });
    });
  };

  return (
    <section className="h-screen flex flex-col gap-3 justify-center items-center">
      <h1 className="text-3xl">LOGIN</h1>
      {error && <p className="text-red-600">Invalid email or password</p>}
      <form
        className="p-5 bg-slate-100 flex flex-col"
        onSubmit={(e) => handleSubmit(e)}
      >
        <input
          type="text"
          className="border-2 px-4 py-2 mb-2"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          className="border-2 px-4 py-2 mb-2"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />

        <button className="px-4 py-2 bg-red-500 mt-5 text-white">Login</button>
      </form>
    </section>
  );
};

export default LoginRoot;
