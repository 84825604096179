import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { useContentApi } from "../../lib/context/content.context";
const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

const castSchema = yup.object().shape({
  firstName: yup.string().required().max(10).min(3),
  lastName: yup.string().max(10).min(3).required(),
  image: yup.mixed().required(),
});

const CastList = ({ cast }: any) => {
  const columns = [
    { field: "castId", headerName: "ID", width: 70 },
    { field: "firstName", headerName: "User name", width: 200 },
    { field: "lastName", headerName: "LastName", width: 200 },
  ];

  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={cast ? cast : []}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
          getRowId={(row) => row.castId}
          checkboxSelection
        />
      </div>
    </div>
  );
};

const ManageCastPage = () => {
  const content = useContentApi();
  const [cast, setCast] = useState<any>(null);
  const [isError, setIsError] = useState(false);
  const [isReloading, setIsReloading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const result = await axios.get(`${SERVER_URL}/casts/`);
      setCast(await result.data);
    };

    fetchUsers();
  }, [isReloading]);

  return (
    <section>
      <p className="text-2xl mb-[2rem]">Manage cast page</p>
      <div className="grid grid-cols-12 gap-10">
        <div className="col-span-4">
          <p>{isError ? "Error submitting" : null}</p>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              image: "",
            }}
            validationSchema={castSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const formData = new FormData();
              formData.append("firstName", values.firstName);
              formData.append("lastName", values.lastName);
              formData.append("image", values.image);

              content.addCast(formData, (success) => {
                setIsReloading(!isReloading);
                resetForm();
                if (success) {
                  return setSubmitting(false);
                }
                setIsError(true);
                return setSubmitting(false);
              });
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="flex flex-col gap-8">
                <Field
                  className="border-2 px-3 py-2"
                  type="text"
                  name="firstName"
                  placeholder="first name"
                />
                <ErrorMessage name="firstName" component="div" />
                <Field
                  className="border-2 px-3 py-2"
                  type="text"
                  name="lastName"
                  placeholder="last name"
                />
                <ErrorMessage name="lastName" component="div" />
                <label
                  htmlFor=""
                  className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
                >
                  Avatar
                  <input
                    type="file"
                    name="image"
                    onChange={(e: any) =>
                      setFieldValue("image", e.target.files[0])
                    }
                  />
                  <ErrorMessage name="image" component="div" />
                </label>
                <button
                  className="bg-cyan-500 py-2 px-3 text-white"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="col-span-8">
          <CastList cast={cast} />
        </div>
      </div>
    </section>
  );
};

export default ManageCastPage;
