import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { useContentApi } from "../../lib/context/content.context";
import { IMovie } from "../../lib/types/movie.types";

const MovieListing = () => {
  const [data, setData] = useState<{ movies: Array<IMovie> }>({ movies: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const contentApi = useContentApi();

  useEffect(() => {
    setIsLoading(true);
    contentApi.getMovies((success, payload) => {
      setIsLoading(false);
      if (success) {
        setIsError(false);
        return setData({ movies: payload });
      }

      return setIsError(true);
    });
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5 flex flex-col gap-5">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="mt-5 flex flex-col gap-5">
        <p>An error occured, Kindly contact admin</p>
      </div>
    );
  }

  if (data.movies.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <p>No content to display</p>
      </div>
    );
  }
  return (
    <div>
      {data.movies.map((movie: IMovie, index: number) => {
        return (
          <div key={index} className="border-b-2 grid grid-cols-12">
            <article className="h-full col-span-3">
              <img className="h-[10rem]" src={movie.posterUrl} alt="" />
              <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                {movie.title}
              </p>
            </article>
            <article className="col-span-2"></article>
            <article className="col-span-3">
              <h5 className="font-medium">File Name</h5>
              <span>{movie.title}</span>
              {movie.type !== "series" ? (
                movie.movieUrl ? (
                  <Link
                    to={`/manage-content/upload-video/${movie.id}?title=${movie.title}`}
                  >
                    <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                      Change Video
                    </p>
                  </Link>
                ) : (
                  <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                    <Link
                      to={`/manage-content/upload-video/${movie.id}?title=${movie.title}`}
                    >
                      Add Video
                    </Link>
                  </p>
                )
              ) : (
                <></>
              )}
              {/* TODO Check urls and switch them shits hapa */}
              {movie.type === "series" && (
                <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                  <Link
                    to={`/manage-content/add-season/${movie.id}/${movie.title}`}
                  >
                    Add Season
                  </Link>
                </p>
              )}
              {movie.type === "series" && movie.seasons.length > 0 && (
                <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                  <Link
                    to={`/manage-content/view-series/${movie.id}/${movie.title}`}
                  >
                    View Seasons
                  </Link>
                </p>
              )}
            </article>
            <article className="col-span-2">
              <h5 className="font-medium">Uploaded by</h5>
              <span>Daniel Montet</span>
            </article>
            <article className="col-span-2 flex flex-col gap-2">
              <span className="text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                {movie.movieUrl && (
                  <a href={movie.movieUrl!} target="_blank">
                    Preview Content
                  </a>
                )}
              </span>
              {movie.type === "series" ? null : (
                <Link
                  to={`/manage-content/edit-movie/${movie.id}`}
                  className="text-cyan-500 cursor-pointer font-medium hover:text-cyan-700"
                >
                  Edit Content
                </Link>
              )}
              <span className="text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                Delete Content
              </span>
            </article>
          </div>
        );
      })}
    </div>
  );
};

export default MovieListing;
