import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../lib/context/auth.context";

export const ConfirmRootRequest = () => {
  let navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const auth = useAuth();

  const handleRefresh = () => {
    auth.refreshEmailConfirmToken((success) => {
      if (!success) {
        return setIsError(true);
      }

      setIsError(false);
      return navigate("/");
    });
  };

  return (
    <section className="h-screen flex flex-col justify-center items-center">
      <div className="flex flex-col items-center justify-center gap-4">
        <h1 className="text-3xl">Confirm your account</h1>
        {isError && <p>Kindly contact an admin</p>}
        <p>
          An email confirmation link was sent to your email. The link will
          expire after 7 days
        </p>
        {/* TODO CREATE REFRESH LINK */}
        <p className="flex gap-5 cursor-pointer">
          Not seeing the link?
          <a
            className="text-blue-600"
            onClick={(e) => {
              handleRefresh();
            }}
          >
            click me to resend link.
          </a>
        </p>
      </div>
    </section>
  );
};
