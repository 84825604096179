import { useEffect, useId, useState } from "react";
import {
  Link,
  Outlet,
  useNavigate,
  NavLink,
  LinkProps,
  useResolvedPath,
  useMatch,
} from "react-router-dom";
import { useAuth } from "../../lib/context/auth.context";

function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={to} {...props}>
      <div
        className={`font-bold hover:text-cyan-600
                ${match && "text-cyan-500"}
                `}
      >
        {children}
      </div>
    </Link>
  );
}

export default function Layout() {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <section className="h-screen grid-rows-6 overflow-hidden">
      <section className="w-full min-h-[3rem] h-[3rem] relative border-b-2 bg-cyan-500"></section>
      <section className="grid grid-cols-12 h-full">
        <section className="col-span-2 border-r-2 p-5">
          <nav className="flex flex-col gap-7">
            <CustomLink to={"/manage-content"}>
              <div className="font-medium">Manage Content</div>
            </CustomLink>
            <CustomLink to={"/manage-staff"}>
              <div className="font-medium">Manage Staff</div>
            </CustomLink>
            <div
              className="cursor-pointer font-medium hover:text-cyan-600"
              onClick={(e) => {
                auth.logout(() => {
                  navigate("/auth/login", { replace: true });
                });
              }}
            >
              Logout
            </div>
          </nav>
        </section>
        <section className="col-span-10 p-5 overflow-y-scroll">
          <Outlet />
        </section>
      </section>
    </section>
  );
}
