export default function ConfirmEmailSuccess() {
  return (
    <div className="h-screen flex flex-col gap-5 justify-center items-center">
      <h1 className="text-blue-600 text-3xl">VERIFICATION SUCCESSFULL</h1>
      <div className="flex gap-5">
        <a href="/">Home</a>
      </div>
    </div>
  );
}
