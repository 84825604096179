import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { IGenre } from "../../lib/types/movie.types";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { getAccessToken } from "../../lib/authTokens/accessToken";
import { useDataApi } from "../../hooks/useDataApi";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { DatePicker, Input, Select } from "antd";
import * as yup from "yup";
import Loader from "../../components/loader/loader";

const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

const CustomSelect: any = Select;
const { Option } = Select;
const { TextArea } = Input;

const movieSchema = yup.object().shape({
  title: yup.string().required().max(255).min(3),
  story: yup.string().max(500).min(3).required(),
  trailer: yup.mixed().required(),
  banner: yup.mixed().required(),
  rating: yup.number().min(1).required(),
  genres: yup.array().required(),
  producerId: yup.number().min(1).max(200),
  casts: yup.array().required(),
  releaseDate: yup.string().required(),
  closeDate: yup.string().required(),
  poster: yup.mixed().required(),
  adverts: yup.array(),
  price: yup.number().min(0)
});

const AddMoviePage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [{ data: genres, isError: genreError, isLoading: genreLoading }] =
    useDataApi(`${SERVER_URL}/movie-genres`);

  const [
    { data: producers, isError: producersError, isLoading: producersLoading },
  ] = useDataApi(`${SERVER_URL}/admin/users/role/PRODUCER`);

  const [{ data: casts, isError: castsError, isLoading: castsLoading }] =
    useDataApi(`${SERVER_URL}/casts`);

  const [{ data: ratings, isError: ratingsError, isLoading: ratingsLoading }] =
    useDataApi(`${SERVER_URL}/rating`);

  const [{ data: adverts, isError: advertsError, isLoading: advertsLoading }] =
    useDataApi(`${SERVER_URL}/advert`);
  return (
    <section className="w-full">
      <h2 className="font-medium text-2xl">Add Content</h2>
      {genreLoading || producersLoading || castsLoading || ratingsLoading ? (
        <Loader />
      ) : (
        <Formik
          validationSchema={movieSchema}
          initialValues={{
            poster: "",
            title: "",
            story: "",
            trailer: "",
            banner: "",
            rating: "",
            genres: "",
            producerId: "",
            casts: "",
            releaseDate: "",
            closeDate: "",
            error: "",
            adverts: "",
            price: ""
          }}
          onSubmit={async (values, { setFieldError, setSubmitting }) => {
            const formData = new FormData();
            formData.append("poster", values.poster);
            formData.append("title", values.title);
            formData.append("story", values.story);
            formData.append("trailer", values.trailer);
            formData.append("banner", values.banner);
            formData.append("rating", values.rating);
            formData.append("genres", values.genres);
            formData.append("producerId", values.producerId!);
            formData.append("casts", values.casts);
            formData.append("releaseDate", values.releaseDate);
            formData.append("closeDate", values.closeDate);
            formData.append("adverts", values.adverts);
            formData.append("price", values.price);

            const res = await axios.post(`${SERVER_URL}/movies`, formData, {
              headers: {
                authorization: `bearer ${getAccessToken()}`,
              },
            });

            if (res.status === 201) {
              // navigate to list of movies
              return navigate("/manage-content/movie-listing");
            }

            setSubmitting(false);
            //show error
            return setFieldError("error", "Error making request");
          }}
        >
          {({ isSubmitting, setFieldValue, touched }) => (
            <Form className="flex flex-col w-2/5">
              <ErrorMessage
                name="error"
                component="div"
                className="text-red-500"
              />
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Title
                <Field type="text" name="title" as={Input} />
                <ErrorMessage name="title" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Story
                <Field type="text" name="story" rows="6" as={TextArea} />
                <ErrorMessage name="story" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Rating / Parental guidance
                <CustomSelect
                  name="rating"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select a rating"
                  onChange={(value: any) => setFieldValue("rating", value)}
                >
                  {ratings
                    ? ratings.data.map((rating: any, index: any) => (
                        <Option key={rating.ratingId}>{rating.name}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="rating" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Price
                <Field type="number" min="0" name="price" as={Input} />
                <ErrorMessage name="price" component="div" />
              </label>

              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Trailer
                <input
                  type="file"
                  name="trailer"
                  onChange={(e: any) =>
                    setFieldValue("trailer", e.target.files[0])
                  }
                />
                <ErrorMessage name="trailer" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Banner
                <input
                  type="file"
                  name="banner"
                  onChange={(e: any) =>
                    setFieldValue("banner", e.target.files[0])
                  }
                />
                <ErrorMessage name="banner" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Poster
                <input
                  type="file"
                  name="poster"
                  onChange={(e: any) =>
                    setFieldValue("poster", e.target.files[0])
                  }
                />
                <ErrorMessage name="poster" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Genres
                <CustomSelect
                  name="genres"
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select genre"
                  onChange={(value: any) => setFieldValue("genres", value)}
                >
                  {genres
                    ? genres.map((genre: IGenre, index: any) => (
                        <Option key={genre.name}>{genre.name}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="genres" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Ads
                <CustomSelect
                  name="adverts"
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select genre"
                  onChange={(value: any) => setFieldValue("adverts", value)}
                >
                  {adverts
                    ? adverts.data.map((ad: any, index: any) => (
                        <Option key={ad.advertId}>{ad.title}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="adverts" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Producer
                <CustomSelect
                  name="producerId"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select producer"
                  onChange={(value: any) => setFieldValue("producerId", value)}
                >
                  {producers
                    ? producers.users.map((producer: any, index: any) => (
                        <Option key={producer.userId}>{producer.email}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="producerId" component="div" />
              </label>

              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Cast
                <CustomSelect
                  mode="multiple"
                  name="casts"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select cast"
                  onChange={(value: any) => {
                    setFieldValue("casts", value);
                  }}
                  options={
                    casts
                      ? casts.map((cast: any) => {
                          return {
                            label: `${cast.firstName} ${cast.lastName}`,
                            value: cast.castId,
                          };
                        })
                      : []
                  }
                ></CustomSelect>
                <ErrorMessage name="casts" component="div" />
              </label>
              <label className="flex flex-col w-full gap-y-[1rem] my-[1rem]">
                ReleaseDate
                <DatePicker
                  name="releaseDate"
                  onChange={(date, dateString) =>
                    setFieldValue("releaseDate", dateString)
                  }
                />
                <ErrorMessage name="releaseDate" component="div" />
              </label>
              <label className="flex flex-col w-full gap-y-[1rem] my-[1rem]">
                CloseDate
                <DatePicker
                  name="closeDate"
                  onChange={(date, dateString) =>
                    setFieldValue("closeDate", dateString)
                  }
                />
                <ErrorMessage name="closeDate" component="div" />
              </label>
              <button type="submit">
                {isSubmitting ? <p>Loading</p> : <p>Submit</p>}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </section>
  );
};

export default AddMoviePage;
function useHistory() {
  throw new Error("Function not implemented.");
}
