import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { getAccessToken } from "../../lib/authTokens/accessToken";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { DatePicker, Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { useDataApi } from "../../hooks/useDataApi";
import { IGenre } from "../../lib/types/movie.types";

const CustomSelect: any = Select;
const { Option } = Select;

const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;
const seriesSchema = yup.object().shape({
  title: yup.string().required().max(255).min(3),
  story: yup.string().max(500).min(3).required(),
  poster: yup.mixed().required(),
  rating: yup.number().min(1).required(),
  producerId: yup.number().min(1).max(200),
  genres: yup.array().required(),
  casts: yup.array().required(),
});

const AddSeriesPage = () => {
  const navigate = useNavigate();
  const [
    { data: producers, isError: producersError, isLoading: producersLoading },
  ] = useDataApi(`${SERVER_URL}/admin/users/role/PRODUCER`);

  const [{ data: genres, isError: genreError, isLoading: genreLoading }] =
    useDataApi(`${SERVER_URL}/movie-genres`);

  const [{ data: casts, isError: castsError, isLoading: castsLoading }] =
    useDataApi(`${SERVER_URL}/casts`);

  const [{ data: ratings, isError: ratingsError, isLoading: ratingsLoading }] =
    useDataApi(`${SERVER_URL}/rating`);

  return (
    <section className="h-screen">
      {genreLoading || producersLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={{
            title: "",
            story: "",
            poster: "",
            rating: "",
            error: "",
            producerId: "",
            genres: "",
            casts: "",
          }}
          onSubmit={async (
            values,
            { resetForm, setSubmitting, setFieldError }
          ) => {
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("story", values.story);
            formData.append("poster", values.poster!);
            formData.append("rating", values.rating);
            formData.append("producerId", values.producerId);
            formData.append("casts", values.casts);
            formData.append("genres", values.genres);

            const response = await axios.post(
              `${SERVER_URL}/movies/series`,
              formData,
              {
                headers: {
                  authorization: `bearer ${getAccessToken()}`,
                },
              }
            );

            if (response.status === 201) {
              resetForm();
              setSubmitting(false);
              return navigate("/manage-content/movie-listing");
            }

            setSubmitting(false);
            setFieldError("error", "Server Error");
          }}
          validationSchema={seriesSchema}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form className="flex flex-col w-2/5">
              <ErrorMessage
                className="text-red-500"
                name="error"
                component="div"
              />
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Title
                <Field type="text" name="title" as={Input} />
                <ErrorMessage name="title" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Story
                <Field type="text" name="story" rows="6" as={TextArea} />
                <ErrorMessage name="story" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Rating / Parental guidance
                <CustomSelect
                  name="rating"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select a rating"
                  onChange={(value: any) => setFieldValue("rating", value)}
                >
                  {ratings
                    ? ratings.data.map((rating: any, index: any) => (
                        <Option key={rating.ratingId}>{rating.name}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="rating" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Poster
                <input
                  type="file"
                  name="poster"
                  onChange={(e: any) =>
                    setFieldValue("poster", e.target.files[0])
                  }
                />
                <ErrorMessage name="poster" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Genres
                <CustomSelect
                  name="genres"
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select genre"
                  onChange={(value: any) => setFieldValue("genres", value)}
                >
                  {genres
                    ? genres.map((genre: IGenre, index: any) => (
                        <Option key={genre.name}>{genre.name}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="genres" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Producer
                <CustomSelect
                  name="producerId"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select producer"
                  onChange={(value: any) => setFieldValue("producerId", value)}
                >
                  {producers
                    ? producers.users.map((producer: any, index: any) => (
                        <Option key={producer.userId}>{producer.email}</Option>
                      ))
                    : null}
                </CustomSelect>
                <ErrorMessage name="producerId" component="div" />
              </label>
              <label
                htmlFor=""
                className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
              >
                Cast
                <CustomSelect
                  mode="multiple"
                  name="casts"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  placeholder="Please select cast"
                  onChange={(value: any) => {
                    setFieldValue("casts", value);
                  }}
                  options={
                    casts
                      ? casts.map((cast: any) => {
                          return {
                            label: `${cast.firstName} ${cast.lastName}`,
                            value: cast.castId,
                          };
                        })
                      : []
                  }
                ></CustomSelect>
                <ErrorMessage name="casts" component="div" />
              </label>
              <button type="submit">
                {isSubmitting ? <p>Loading</p> : <p>Submit</p>}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </section>
  );
};

export default AddSeriesPage;
