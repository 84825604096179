import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { getAccessToken } from "../../lib/authTokens/accessToken";
import { IMovie } from "../../lib/types/movie.types";

const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

export default function ViewEpisodesPage() {
  const params = useParams();
  const [episodes, setEpisodes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getEpisodes = async () => {
      setIsLoading(true);
      const response = await fetch(
        `${SERVER_URL}/movies/series/${params.seriesId}/seasons/${params.seasonId}/episodes`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `bearer ${getAccessToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        setIsError(false);
        const result = await response.json();
        return setEpisodes(result);
      }

      setIsLoading(false);
      return setIsError(true);
    };

    getEpisodes();
  }, []);

  if (isLoading) {
    return (
      <div className="mt-5 flex flex-col gap-5">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="mt-5 flex flex-col gap-5">
        <p>An error occured, Kindly contact admin</p>
      </div>
    );
  }

  if (episodes!.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <p>No content to display</p>
      </div>
    );
  }

  return (
    <div>
      {episodes!.map((movie: any, index: number) => {
        return (
          <div key={index} className="border-b-2 grid grid-cols-12">
            <article className="h-full col-span-3">
              <img className="h-[10rem]" src={movie.posterUrl} alt="" />
              <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                {movie.title}
              </p>
            </article>
            <article className="col-span-2"></article>
            <article className="col-span-3">
              <h5 className="font-medium">File Name</h5>
              <span>{movie.episode_title}</span>
              {movie.movieUrl ? (
                <Link
                  to={`/manage-content/upload-video/${movie.episodeId}?title=${movie.title}`}
                >
                  <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                    Change Video
                  </p>
                </Link>
              ) : (
                <p className="my-1 text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                  <Link
                    to={`/manage-content/upload-video/${movie.episodeId}?title=${movie.title}`}
                  >
                    Add Video
                  </Link>
                </p>
              )}
            </article>
            <article className="col-span-2">
              <h5 className="font-medium">Uploaded by</h5>
              <span>Daniel Montet</span>
            </article>
            <article className="col-span-2 flex flex-col gap-2">
              <span className="text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                {movie.movieUrl && (
                  <a href={movie.movieUrl!} target="_blank">
                    Preview Content
                  </a>
                )}
              </span>
              <span className="text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                Edit Content
              </span>
              <span className="text-cyan-500 cursor-pointer font-medium hover:text-cyan-700">
                Delete Content
              </span>
            </article>
          </div>
        );
      })}
    </div>
  );
}
