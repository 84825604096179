import { useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { Link, Outlet } from "react-router-dom";
import { useContentApi } from "../../lib/context/content.context";
import { IMovie } from "../../lib/types/movie.types";

const ManageContent = () => {
  return (
    <ScrollBar className="max-h-screen">
      <h2 className="font-medium text-2xl">Content Library</h2>
      <div className="flex gap-5 mt-3">
        <Link to={"movie-listing"}>
          <button className="bg-cyan-500 px-5 py-1 text-white text-sm hover:bg-cyan-700 ">
            Videos
          </button>
        </Link>
        <Link to={"add-movie"}>
          <button className="bg-cyan-500 px-5 py-1 text-white text-sm hover:bg-cyan-700 ">
            Add Movie
          </button>
        </Link>
        <Link to={"add-series"}>
          <button className="bg-cyan-500 px-5 py-1 text-white text-sm hover:bg-cyan-700 ">
            Add Series
          </button>
        </Link>
        <Link to={"manage-genres"}>
          <button className="bg-cyan-500 px-5 py-1 text-white text-sm hover:bg-cyan-700 ">
            Manage Genres
          </button>
        </Link>
        <Link to={"manage-cast"}>
          <button className="bg-cyan-500 px-5 py-1 text-white text-sm hover:bg-cyan-700 ">
            Manage Cast
          </button>
        </Link>
        <Link to={"manage-ratings"}>
          <button className="bg-cyan-500 px-5 py-1 text-white text-sm hover:bg-cyan-700 ">
            Manage Ratings
          </button>
        </Link>
        <Link to={"manage-adverts"}>
          <button className="bg-cyan-500 px-5 py-1 text-white text-sm hover:bg-cyan-700">
            Manage Adverts
          </button>
        </Link>
      </div>
      <hr className="my-5" />
      <div className="mt-5">
        <Outlet />
      </div>
    </ScrollBar>
  );
};

export default ManageContent;
