import axios from "axios";
import React, { useState, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAccessToken,
  getIsLoggedIn,
  getRefreshToken,
  setAccessToken,
  setIsLoggedIn,
  setRefreshToken,
} from "../authTokens/accessToken";
import {
  IMe,
  IRootMeta,
  IUserLoginInput,
  IUserUpdateInput,
} from "../types/user.types";

const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

interface AuthContextType {
  isLoggedIn: () => boolean;
  rootLogin: (
    userInput: IUserLoginInput,
    callback: (success: boolean) => void
  ) => void;
  rootUpdate: (
    userInput: IUserUpdateInput,
    callback: (success: boolean) => void
  ) => void;
  login: (
    userInput: IUserLoginInput,
    callback: (success: boolean) => void
  ) => void;
  logout: (callback: VoidFunction) => void;
  getRootMeta: () => Promise<any>;
  rootConfirm: (token: string, callback: (success: boolean) => void) => void;
  refreshEmailConfirmToken: (callback: (success: boolean) => void) => void;

  me: (callback: (success: boolean, payload?: any) => void) => void;
  refreshToken: () => any;
}

export const AuthContext = createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const login = async (userInput: IUserLoginInput, callback: any) => {
    const response = await fetch(`${SERVER_URL}/admin/auth/login`, {
      method: "POST",
      // mode: "cors",
      body: JSON.stringify(userInput),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status === 200) {
      const { accessToken, refreshToken } = await response.json();
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setIsLoggedIn("true");
      return callback(true);
    }

    setIsLoggedIn("false");
    setAccessToken("");
    setRefreshToken("");
    return callback(false);
  };

  const rootLogin = async (userInput: IUserLoginInput, callback: any) => {
    let response: any = await fetch(`${SERVER_URL}/admin/auth/login-root`, {
      method: "POST",
      body: JSON.stringify(userInput),
      headers: {
        "Content-Type": "application/json",
      },
      //   mode: "cors"
    });

    if (response.status === 200) {
      // set access token
      const { accessToken, refreshToken } = await response.json();
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setIsLoggedIn("true");
      return callback(true);
    }

    setIsLoggedIn("false");
    setAccessToken("");
    setRefreshToken("");
    return callback(false);
  };

  const rootUpdate = async (userInput: IUserUpdateInput, callback: any) => {
    let response: any = await fetch(`${SERVER_URL}/admin/root/root`, {
      method: "PUT",
      body: JSON.stringify(userInput),
      headers: {
        "Content-Type": "application/json",
        authorization: `bearer ${getAccessToken()}`,
      },
      // credentials: "include",
      // mode: "cors",
    });

    if (response.status === 200) {
      return callback(true);
    }

    return callback(false);
  };

  const logout = (callback: any) => {
    setIsLoggedIn("false");
    setAccessToken("");
    setRefreshToken("");
    callback();
  };

  const rootConfirm = async (token: string, callback: any) => {
    let response: any = await fetch(
      `${SERVER_URL}/admin/auth/confirm-root/${token}`,
      {
        // credentials: "include",
        // mode: "cors",
      }
    );

    if (response.status === 200) {
      return callback(true);
    }

    return callback(false);
  };

  const isLoggedIn = () => {
    const state = getIsLoggedIn();
    if (state === "true") {
      return true;
    }

    return false;
  };

  const getRootMeta = async () => {
    let response: any = await fetch(`${SERVER_URL}/admin/root`, {
      // mode: "cors",
      // credentials: "include",
    });
    return response.json();
  };

  const refreshEmailConfirmToken = async (callback: any) => {
    let response: any = await fetch(
      `${SERVER_URL}/admin/auth/refresh-confirm-email-token`,
      {
        // mode: "cors",
        // credentials: "include",
        headers: {
          authorization: `bearer ${getAccessToken()}`,
        },
      }
    );
  };

  const me = async (callback: any) => {
    let response: any = await fetch(`${SERVER_URL}/admin/auth/me`, {
      // credentials: "include",
      // mode: "cors",
      headers: {
        authorization: `bearer ${getAccessToken()}`,
      },
    });

    if (response.status === 200) {
      return callback(true, response.json());
    }

    return callback(false);

    // not authorized
    // return null;
  };

  const refreshToken = async () => {
    try {
      let response: any = await axios.post(
        `${SERVER_URL}/auth/refresh-token`,
        undefined,
        {
          headers: {
            refresh: `refresh ${getRefreshToken()}`,
          },
        }
      );
      // {success,data: {atk,rtk}}
      if (response.status === 201) {
        const { success, data } = response.data;
        if (success) {
          console.log(data);
          setAccessToken(data.accessToken);
          setRefreshToken(data.refreshToken);
          return setIsLoggedIn("true");
        }

        setRefreshToken("");
        setAccessToken("");
        return setIsLoggedIn("false");
      }
    } catch (error) {
      console.log(error);
      setAccessToken("");
      setRefreshToken("");
      return setIsLoggedIn("false");
    }
  };

  const value = {
    isLoggedIn,
    login,
    logout,
    getRootMeta,
    rootLogin,
    rootUpdate,
    rootConfirm,
    refreshEmailConfirmToken,
    me,
    refreshToken,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const RequireRoot = ({ children }: { children: JSX.Element }) => {
  let navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const result = async () => {
      const { state } = await auth.getRootMeta();
      console.log("state of root", state);

      if (state === "exists") {
        navigate("/auth/login-root");
      }

      if (state === "firstTimeLogin") {
        navigate("/auth/update-root");
      }

      if (state === "isCredentialsUpdated") {
        navigate("/auth/confirm-root-request");
      }

      if (state === "isConfirmed") {
        return children;
      }
    };

    result();
  }, []);

  return children;
};

export const GetRefreshToken = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();

  useEffect(() => {
    auth.refreshToken();
  }, []);

  return children;
};

export const RequireLogin = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const isLoggedIn = auth.isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate("/auth/login");
    }
  }, []);

  return children;
};
