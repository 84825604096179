import axios from "axios";
import React, { useState, createContext, useEffect } from "react";
import { getAccessToken } from "../authTokens/accessToken";
const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

interface ContentContextType {
  getMovies: (callback: (success: boolean, payload: any) => void) => void;
  addContent: (movieInput: any, callback: (success: boolean) => void) => void;
  getGenres: (callback: (success: boolean, payload?: any) => void) => void;
  addGenre: (genreInput: any, callback: (success: boolean) => void) => void;
  addCast: (castInput: any, callback: (success: boolean) => void) => void;
  getCast: (callback: (success: boolean, payload?: any) => void) => void;
  addRating: (ratingInput: any, callback: (success: boolean) => void) => void;
  getRatings: (callback: (success: boolean, payload?: any) => void) => void;
}

export const ContentContext = createContext<ContentContextType>(null!);

export function useContentApi() {
  return React.useContext(ContentContext);
}

export const ContentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const getMovies = async (callback: any) => {
    const response = await fetch(`${SERVER_URL}/movies`, {
      // mode: "cors",
      // credentials: "include",
      headers: {
        authorization: `bearer ${getAccessToken()}`,
      },
    });

    if (response.status === 200) {
      return callback(true, await response.json());
    }

    return callback(false, null);
  };

  const addContent = async (movieInput: any, callback: any) => {
    const response = await fetch(`${SERVER_URL}/movies`, {
      method: "POST",
      body: movieInput,
      // credentials: "include",
      // mode: "cors",
      headers: {
        authorization: `bearer ${getAccessToken()}`,
      },
    });

    if (response.status === 201) {
      return callback(true);
    }

    return callback(false);
  };

  const getGenres = async (callback: any) => {
    const response = await fetch(`${SERVER_URL}/movie-genres`, {
      // mode: "cors",
      // credentials: "include",
      headers: {
        authorization: `bearer ${getAccessToken()}`,
      },
    });

    if (response.status === 200) {
      return callback(true, await response.json());
    }

    return callback(false);
  };

  const addGenre = async (genreInput: any, callback: any) => {
    const response = await fetch(`${SERVER_URL}/movie-genres`, {
      method: "POST",
      body: JSON.stringify(genreInput),
      // credentials: "include",
      // mode: "cors",
      headers: {
        authorization: `bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
    });

    if (response.status === 201) {
      return callback(true);
    }

    return callback(false);
  };

  const addCast = async (castInput: any, callback: any) => {
    console.log(castInput);
    try {
      const response = await axios.post(`${SERVER_URL}/casts`, castInput, {
        headers: {
          authorization: `bearer ${getAccessToken()}`,
        },
      });

      if (response.status === 201) {
        return callback(true);
      }

      return false;
    } catch (error) {
      return callback(false);
    }
  };

  const getCast = async (callback: any) => {
    try {
      const response = await axios.get(`${SERVER_URL}/casts`, {
        headers: {
          authorization: `bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        return callback(true, response.data);
      }
    } catch (error) {
      return callback(false);
    }
  };

  const addRating = async (ratingInput: any, callback: any) => {
    try {
      const response = await axios.post(`${SERVER_URL}/rating`, ratingInput, {
        headers: {
          authorization: `bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        return callback(true);
      }
    } catch (error) {
      return callback(false);
    }
  };

  const getRatings = async (callback: any) => {
    try {
      const response = await axios.get(`${SERVER_URL}/rating`, {
        headers: {
          authorization: `bearer ${getAccessToken()}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        return callback(true, response.data.data);
      }
    } catch (error) {
      return callback(false);
    }
  };

  const value = {
    getMovies,
    addContent,
    getGenres,
    addGenre,
    addCast,
    getCast,
    addRating,
    getRatings,
  };
  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
};
