import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import { useContentApi } from "../../lib/context/content.context";
import Loader from "../../components/loader/loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { getAccessToken } from "../../lib/authTokens/accessToken";

const SERVER_URL = process.env.REACT_APP_DEV_SERVER_URL;

const episodeSchema = yup.object().shape({
  title: yup.number().required().max(255).min(1),
  story: yup.string().max(255).min(3).required(),
  heading: yup.string().max(255).min(3).required(),
  poster: yup.mixed().required(),
  movie: yup.mixed().required(),
  banner: yup.mixed().required(),
  price: yup.number().required().min(0),
});

const AddContentForm = ({ setIsLoading, setIsError }: any) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Formik
      validationSchema={episodeSchema}
      initialValues={{
        title: "",
        story: "",
        heading: "",
        poster: "",
        movie: "",
        banner: "",
        error: "",
        price: "",
      }}
      onSubmit={async (values, { resetForm, setSubmitting, setFieldError }) => {
        const formData = new FormData();
        formData.append("poster", values.poster);
        formData.append("title", values.title);
        formData.append("story", values.story);
        formData.append("movie", values.movie);
        formData.append("banner", values.banner);
        formData.append("heading", values.heading);
        formData.append("price", values.price);

        const res = await axios.post(
          `${SERVER_URL}/movies/series/${params.seriesId}/seasons/${params.seasonId}/episodes`,
          formData,
          {
            headers: {
              authorization: `bearer ${getAccessToken()}`,
            },
          }
        );

        if (res.status === 200) {
          resetForm();
          setSubmitting(false);
          return navigate(
            `/manage-content/view-series/${params.seriesId}/${params.seriesTitle}`
          );
        }

        setSubmitting(false);
        setFieldError("error", "database error");
      }}
    >
      {({ isSubmitting, setFieldValue, touched }) => (
        <Form className="flex flex-col w-2/5">
          <ErrorMessage name="error" component="div" />
          <label
            htmlFor=""
            className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
          >
            Title
            <Field type="number" name="title" as={Input} />
            <ErrorMessage name="title" component="div" />
          </label>
          <label
            htmlFor=""
            className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
          >
            Story
            <Field type="text" name="story" rows="6" as={TextArea} />
            <ErrorMessage name="story" component="div" />
          </label>
          <label
            htmlFor=""
            className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
          >
            Price
            <Field type="number" name="price" as={Input} min="0" />
            <ErrorMessage name="price" component="div" />
          </label>
          <label
            htmlFor=""
            className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
          >
            Heading
            <Field type="text" name="heading" rows="6" as={TextArea} />
            <ErrorMessage name="heading" component="div" />
          </label>

          <label
            htmlFor=""
            className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
          >
            Movie
            <input
              type="file"
              name="movie"
              onChange={(e: any) => setFieldValue("movie", e.target.files[0])}
            />
            <ErrorMessage name="movie" component="div" />
          </label>
          <label
            htmlFor=""
            className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
          >
            Banner
            <input
              type="file"
              name="banner"
              onChange={(e: any) => setFieldValue("banner", e.target.files[0])}
            />
            <ErrorMessage name="banner" component="div" />
          </label>
          <label
            htmlFor=""
            className="flex flex-col w-full gap-y-[1rem] my-[1rem]"
          >
            Poster
            <input
              type="file"
              name="poster"
              onChange={(e: any) => setFieldValue("poster", e.target.files[0])}
            />
            <ErrorMessage name="poster" component="div" />
          </label>

          <button>{isSubmitting ? <p>Loading</p> : <p>Submit</p>}</button>
        </Form>
      )}
    </Formik>
  );
};

const AddEpisodePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <section className="w-full">
      <h2 className="font-medium text-2xl">Add Content</h2>
      {isError && (
        <div className="flex flex-col gap-5 justify-center items-center">
          <p>
            Sorry, we are unable to process your request at the moment. Please
            try again later
          </p>
          <button
            className="bg-cyan-500 hover:bg-cyan-700 text-white mt-5 py-1 px-10"
            onClick={(e) => setIsError(false)}
          >
            Back
          </button>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <AddContentForm isLoading={isLoading} setIsLoading={setIsLoading} />
      )}
    </section>
  );
};

export default AddEpisodePage;
